ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li {
  padding: 10px 20px;
  position: relative;
  display: flex;
  align-items: flex-start;
  line-height: 1;
}

.configBody {
  height: 200px;
}
